import React from "react";
import Navbar from "./navs/navbar";
import Footer from "./navs/footer";

const Layout = ({ children }) => (
  <>
    <div className="container-fluid">
      <Navbar />
      <div className="main">{children}</div>

      <Footer />
      <footer className="d-none">
        <div className="">
          <div className="">
            &copy; {new Date().getFullYear()}, Built with{" "}
            <a href="https://www.sanity.io">Sanity</a> &amp;{" "}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </div>
        </div>
      </footer>
    </div>
  </>
);

export default Layout;
